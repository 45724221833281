const form = document.querySelector('#call-form');

const onFormSubmit = (evt) => {
  evt.preventDefault();
  const formElement = evt.target;
  const formData = new FormData(formElement);
  const tel = formData.get('tel');

  const params = new URLSearchParams({
    'FIELDS[PHONE][0][VALUE]': tel,
    'FIELDS[TITLE]': 'комплект VIP'
  });

  const URL = 'https://mygenetics.bitrix24.ru/rest/7/z0mgc0b2o3ce2ee7/crm.lead.add.json';

  fetch(URL + '?' + params,
    {
      method: 'GET'
    }
  )
    .then(() => formElement.classList.add('success'))
    .catch(() => formElement.classList.add('error'));
}

export const initCallForm = () => {
  if (form) {
    form.addEventListener('submit', onFormSubmit);
  }
};

