const previewBlock = document.getElementById('preview');
const button = document.querySelector('[data-buy-button]');
let prevIsVisible = false;

const showBuyButton = () => {
  const showHideButton = (isVisible) => {
    if (isVisible) {
      button.classList.add('is-visible');
    } else {
      button.classList.remove('is-visible');
    }
  };

  window.addEventListener('scroll', function () {
    const isVisible = previewBlock.getBoundingClientRect().bottom < 0;

    if (prevIsVisible !== isVisible) {
      showHideButton(isVisible);
    }

    prevIsVisible = isVisible;
  });
};

export const initBuyButton = () => {
  showBuyButton();
};
