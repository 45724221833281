import { iosVhFix } from './utils/ios-vh-fix';
import { initMobileMenu } from './mobile-menu';
import { initAccordion } from './accordion';
import { initCallForm } from './call-form';
import { initCarousel } from './utils/carousel';
import { initBuyButton } from './buy-button';

window.addEventListener('DOMContentLoaded', () => {
  iosVhFix();

  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initMobileMenu();
    initAccordion();
    initCallForm();
    initCarousel();
    initBuyButton();
  });
});
