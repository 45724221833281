import {FocusLock} from './utils/focus-lock';
import {ScrollLock} from './utils/scroll-lock';

const mainNav = document.querySelector('.main-nav');
const toggleButton = mainNav.querySelector('.main-nav__toggle');
const linkClass = 'main-nav__link';
const scrollLock = new ScrollLock();
const focusLock = new FocusLock();

const breakpoint = window.matchMedia('(min-width:769px)');
const breakpointChecker = () => {
  if (breakpoint.matches && mainNav.classList.contains('is-open')) {
    closeMenu();
  }
};

const openMenu = () => {
  mainNav.classList.add('is-open');
  scrollLock.disableScrolling();
  focusLock.lock('.main-nav', false);

  toggleButton.removeEventListener('click', openMenu);
  toggleButton.addEventListener('click', closeMenu);
};

const closeMenu = () => {
  mainNav.classList.remove('is-open');
  scrollLock.enableScrolling();
  focusLock.unlock();

  toggleButton.removeEventListener('click', closeMenu);
  toggleButton.addEventListener('click', openMenu);
};

const checkLinkClick = (evt) => {
  if (evt.target.classList.contains(linkClass)) {
    closeMenu();
  }
};

export const initMobileMenu = () => {
  if (mainNav && toggleButton) {
    toggleButton.addEventListener('click', openMenu);
    mainNav.addEventListener('click', checkLinkClick);
    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }
  // при переходе тоже закрывать меню
};
