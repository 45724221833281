import EmblaCarousel from 'embla-carousel';

const emblaNode = document.querySelector('.embla');
const options = {
  loop: false,
  breakpoints: {'(min-width: 1380px)': { active: false }}
};

export const initCarousel = () => {
  const emblaApi = EmblaCarousel(emblaNode, options);
};
