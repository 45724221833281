const accordion = document.querySelector('[data-accordion]');

let openedElement = null;

const toggleAccordion = (evt) => {
  const isHeaderTriggered = evt.target.hasAttribute('data-accordion-trigger');
  const isChildTriggered = evt.target.parentNode.hasAttribute('data-accordion-trigger');
  let element = null;

  if (isHeaderTriggered) {
    element = evt.target.parentNode;
  } else if (isChildTriggered) {
    element = evt.target.parentNode.parentNode;
  }

  if (element) {
    const isOpening = !element.classList.contains('is-open') ? true : false;

    if (isOpening && openedElement) {
      openedElement.classList.remove('is-open');
      openedElement = null;
    }
    
    element.classList.toggle('is-open');
    openedElement = element;
  }
};

export const initAccordion = () => {
  if (accordion) {
    accordion.addEventListener('click', toggleAccordion);
  }
};
